<template>
  <div>
    <v-navigation-drawer
      class="hidden-md-and-up header-one-page-nav-drawer"
      v-model="drawer"
      fixed
      width="320"
    >
      <v-list-item class="pa-5">
        <div class="logo">
          <img :src="$store.state.iglesia.Logo" alt="brand-image" />
        </div>
        <v-spacer></v-spacer>
        <v-btn
          class="close-icon"
          icon
          @click="drawer = !drawer"
          v-html="iconSvg(closeIcon)"
        >
        </v-btn>
      </v-list-item>
      <scrollactive
        active-class="v-btn--active"
        bezier-easing-value=".5,0,.35,1"
        :offset="71"
      >
        <v-list>
          <v-list-item
            link
            v-for="child in $store.state.menu"
            :to="child.hash"
            :key="child.title"
            :ripple="false"
          >
            <v-list-item-content>
              <v-list-item-title v-text="child.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <span v-if="$store.state.admin.Admin == 1">
            <v-list-item
              v-for="(item, index) in $store.state.menuAdmin"
              :key="index"
              :to="item.to"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </span>
        </v-list>
      </scrollactive>
    </v-navigation-drawer>
    <!-- End sidebar mobile menu -->

    <v-app-bar
      dark
      color="transparent"
      fixed
      elevate-on-scroll
      class="header-one-page-nav"
    >
      <a class="logo" @click="dialog = true">
        <!-- <slot name="logo"></slot>
                <slot name="logo-dark"></slot>
                 -->
        <img :src="$store.state.iglesia.Logo" alt="" />
        <div class="ml-2">{{ $store.state.iglesia.Nombre }}</div>
      </a>
      <!-- End brand logo -->
      <v-spacer></v-spacer>

      <v-toolbar-items class="hidden-xs-only hidden-sm-only height-auto">
        <scrollactive
          active-class="v-btn--active"
          bezier-easing-value=".5,0,.35,1"
        >
          <!-- <v-btn
            v-for="item in $store.state.menu"
            :key="item.title"
            :to="item.hash ? item.hash : item.to"
            link
            :ripple="false"
            text
            class="scrollactive-item"
            >{{ item.title }}
          </v-btn> -->

          <span v-for="(item, index) in $store.state.menu" :key="index">
            <button
              @click="$router.push(item.to)"
              class="donaciones px-4 py-2 rounded-pill"
              v-if="item.btn"
            >
              {{ item.title }}
            </button>

            <v-btn v-else :ripple="false" text :to="item.hash ? item.hash : item.to" exact>{{
              item.title
            }}</v-btn>
          </span>

          <v-menu
            v-if="$store.state.admin.Admin == 1"
            open-on-hover
            :position-y="80"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                link
                :ripple="false"
                text
                class="scrollactive-item"
              >
                Editar
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-for="(item, index) in $store.state.menuAdmin"
                :key="index"
                :to="item.to"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </scrollactive>
      </v-toolbar-items>
      <!-- End header menu item -->
      <v-btn
        :ripple="false"
        icon
        class="ma-0 pa-0 hidden-md-and-up menu_icon"
        @click="drawer = !drawer"
        v-html="iconSvg(icon)"
      >
      </v-btn>

      <a
        @click="
          Goto(
            `/${$route.params.Nombre_Id}/${$store.state.iglesia.Nav_4}/${$store.state.lastPredica.EventoID}`
          )
        "
        :class="
          $store.state.counterEnded == true
            ? 'btn-default btn-border btn-opacity'
            : 'white--text'
        "
        v-if="$store.state.lastPredica"
      >
        <flip-countdown
          v-if="$store.state.lastPredica && $store.state.counterEnded == false"
          :deadline="$store.state.lastPredica.Fecha"
          @timeElapsed="timeElapsedHandler"
          :labels="labels"
        ></flip-countdown>
        <span v-else>Watch now</span>
      </a>
      <!-- End mobile menu icon -->
    </v-app-bar>
    <!-- End top header navbar -->

    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Acceder a su cuenta
        </v-card-title>

        <v-card-text class="mt-8">
          <v-text-field
            label="Email"
            outlined
            v-model="$store.state.admin.Email"
          />
          <v-text-field
            label="Password"
            outlined
            v-model="$store.state.admin.Password"
          />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="Login"> Confirmar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import feather from "feather-icons";
import FlipCountdown from "vue2-flip-countdown";
export default {
  components: { FlipCountdown },
  data: () => ({
    dialog: false,
    drawer: false,
    items: [
      { title: "Home", to: "#home" },
      { title: "Service", to: "#service" },
      { title: "About", to: "#about" },
      { title: "Portfolio", to: "#portfolio" },
      { title: "Team", to: "#team" },
      { title: "Testimonial", to: "#tesimonial" },
      { title: "Blog", to: "#blog" },
      { title: "Contact", to: "#contact" },
    ],
    icon: "menu",
    closeIcon: "x",
  }),
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
    Login() {
      this.$store.dispatch("Login").then((res) => {
        this.dialog = false;
      });
    },
    Goto(web) {
      if (this.$store.state.counterEnded == true) {
        this.$router.push(web);
      }
    },
  },
};
</script>
